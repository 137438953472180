import React, { Component } from 'react'
import { Admin, Resource } from 'react-admin'
import { restClient } from 'ra-data-feathers'
import Login from './Login'
import { MachineList, MachineShow, MachineEdit, MachineCreate } from './Machine'
import { MachineNoteEdit, MachineNoteCreate } from './MachineNote'
import { PatientList, PatientShow, PatientEdit, PatientCreate } from './Patient'
import { UserList, UserShow, UserEdit, UserCreate } from './User'
import { GroupList, GroupShow, GroupEdit, GroupCreate } from './Group'
import { GroupMembershipCreate, GroupMembershipEdit } from './GroupMembership'
import { LocationList, LocationShow, LocationEdit, LocationCreate } from './Location'
import { AnnouncementList, AnnouncementShow, AnnouncementEdit, AnnouncementCreate } from './Announcement'
import NotAuthorized from './NotAuthorized'

import app from 'common/client/app'
import authProvider from '../authProvider'
const { addParams } = require('../util')

class App extends Component {
  render () {
    app.hooks({ before: addParams })
    return (
      // use ListGuesser and EditGuesser to get input lists
      <Admin loginPage={Login} authProvider={authProvider} dataProvider={restClient(app, { id: '_id', usePatch: true })}>
        {
          permissions => {
            if (permissions) {
              return [
                <Resource name="patient" list={PatientList} show={PatientShow} edit={PatientEdit} create={PatientCreate} />,
                <Resource name="group" list={GroupList} show={GroupShow} edit={GroupEdit} create={GroupCreate} options={{ label: 'Customers' }} />,
                <Resource name="groupMembership" create={GroupMembershipCreate} edit={GroupMembershipEdit} />,
                <Resource name="location" list={LocationList} show={LocationShow} edit={LocationEdit} create={LocationCreate}/>,
                <Resource name="machine" list={MachineList} show={MachineShow} edit={MachineEdit} create={MachineCreate} />,
                <Resource name="machineNote" create={MachineNoteCreate} edit={MachineNoteEdit} />,
                <Resource name="user" list={UserList} show={UserShow} edit={UserEdit} create={UserCreate} />,
                <Resource name="announcement" list={AnnouncementList} show={AnnouncementShow} edit={AnnouncementEdit} create={AnnouncementCreate} />,
                <Resource name="ride" />,
              ]
            }
            else {
              return [ <NotAuthorized /> ]
            }
          }
        }
      </Admin>
    )
  }
}

export default App
