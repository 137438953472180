import React from 'react'
import { Pagination } from 'react-admin'
const { paramsForServer } = require('feathers-hooks-common')
const xss = require('xss')

export const addParams = (context) => {
  if (context.method === 'create' || context.method === 'remove') return
  context.params.query = paramsForServer({
    query: context.params.query,
    $ignoreDeletedAt: true,
  }).query
}

export const paginationProps = {
  perPage: 50,
  pagination: <Pagination rowsPerPageOptions={[25, 50, 100]} />
}

export const selectProps = {
  perPage: 0,
  sort: { field: 'title', order: 'ASC' }
}

/**
 * Provide a default `source` argument for render functions below
 * @param {object} props The props from the render function.
 * @param {object} source The name of the default source property to use.
 */
const defaultSource = (props = {}, defaultSource) => (props.source || defaultSource)

export const renderName = (props) => {
  let source = defaultSource(props, 'name')
  return (record) => {
    if (record[source]) {
      let given = record[source].given ? record[source].given + ' ' : ''
      let family = record[source].family ? record[source].family : ''
      return (`${given}${family}`)
    }
  }
}

export const renderRoles = (props) => {
  let source = defaultSource(props, 'roles')
  return (record) => {
    if (record[source]) {
      return (`${record[source].join(', ')}`)
    }
  }
}

export const renderAddress = (props) => {
  let source = defaultSource(props, 'address')
  return (record) => {
    if (record[source]) {
      let address = record[source]
      let area = [[address.locality, address.administrative_area], [address.postal_code]]
        .map(part => part.filter(Boolean).join(', '))
        .filter(str => str.length)
        .join(' ')
      return [
        address.thoroughfare,
        address.premise,
        area
      ].filter(Boolean).join('\n')
    }
  }
}

export const renderExcerpt = (props) => {
  let source = defaultSource(props, 'note')
  return (record) => {
    if (record[source]) {
      let excerpt = record[source]
      excerpt = xss(excerpt, {
        whiteList: [],
        stripIgnoreTag: true,
      })
      if (excerpt.length > 85) {
        return excerpt.substring(0, 84) + '...'
      }
      return excerpt
    }
  }
}

export const $type = {
  date: 9,
  null: 10,
}
