import React from 'react'
import { paginationProps, selectProps, $type } from '../util'
import { List, Show, Datagrid, TextField, DateField, ReferenceArrayInput, SelectArrayInput,
  Edit, SimpleForm, ReferenceArrayField, SingleFieldList, ChipField, SimpleShowLayout,
  TextInput, Create, Filter, EditButton, DeleteButton, SelectInput } from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import { DateInput } from 'react-admin-date-inputs'

const AnnouncementTitle = ({ record }) => {
  return <span>{record && record.title ? record.title : 'Announcement'}</span>
}

export const AnnouncementList = (props) => {
  return (
    <List title="Announcement List" {...props} filters={ <AnnouncementFilter/> } {...paginationProps} filterDefaultValues={{ deletedAt: { $type: $type.null } }}>
      <Datagrid rowClick="show">
        <TextField label="Title" source="title" />
        <ReferenceArrayField label="Customer" source="group" reference="group">
          <SingleFieldList>
            <ChipField source="title" />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField label="Expire" source="expire" />
        <DateField label="Updated" source="updatedAt" />
        <EditButton />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  )
}

export const AnnouncementShow = props => (
  <Show title={<AnnouncementTitle />} {...props}>
    <SimpleShowLayout>
      <TextField label="Title" source="title" />
      <ReferenceArrayField label="Customer" source="group" reference="group">
        <SingleFieldList>
          <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField label="Expire" source="expire" options={{ year: 'numeric', month: 'long', day: 'numeric' }} />
      <DateField label="Updated" source="updatedAt" options={{ year: 'numeric', month: 'long', day: 'numeric' }} />
    </SimpleShowLayout>
  </Show>
)

export const AnnouncementEdit = (props) => {
  return (
    <Edit title={'Edit Announcement'} undoable={false} {...props}>
      <SimpleForm>
        <TextInput label="Title" source="title" />
        <RichTextInput label="Description" source="description" />
        <DateInput label="Expiry" source="expire" options={{ clearable: true, format: 'MMMM dd, yyyy' }} />
        <ReferenceArrayInput label="Customer" source="group" reference="group" {...selectProps} >
          <SelectArrayInput optionText="title" />
        </ReferenceArrayInput>
        <TextInput label="Archived On" source="deletedAt" />
      </SimpleForm>
    </Edit>
  )
}

export const AnnouncementCreate = (props) => {
  return (
    <Create title={'Create Announcement'} {...props}>
      <SimpleForm>
        <TextInput label="Title" source="title" />
        <RichTextInput label="Description" source="description" />
        <DateInput label="Expiry" source="expire" options={{ clearable: true, format: 'MMMM dd, yyyy' }} />
        <ReferenceArrayInput label="Customer" source="group" reference="group" {...selectProps} >
          <SelectArrayInput optionText="title" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  )
}

export const AnnouncementFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Title Search" source="title[$regex]" alwaysOn />
    <SelectInput label="Status" source="deletedAt[$type]" choices={[
      { id: $type.null, name: 'Active' },
      { id: $type.date, name: 'Archived' },
    ]} alwaysOn required/>
  </Filter>
)

export default {
  AnnouncementList,
  AnnouncementEdit,
  AnnouncementCreate,
  AnnouncementFilter,
}
